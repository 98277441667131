<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:actions="actions"
			:externalFilter="filter()"
			:modelColumnDefs="modelColumnDefs"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modelColumnDefs="modelColumnDefs"
			:readOnly="true"
			:modalDialog="modalDialog"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		></pui-datatable>
		<portcallsModals :modelName="model"></portcallsModals>
	</div>
</template>

<script>
import portcallsActions from './PortcallsActions';
import portcallsModals from './PortcallsModals.vue';
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'portcalls-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		portcallsModals: portcallsModals
	},
	data() {
		return {
			model: 'portcalls',
			modelName: 'portcalls',
			actions: portcallsActions.actions,
			modelColumnDefs: {
				checkboxes: {
					render: (data) => {
						return this.returnStatusList(data);
					}
				},
				etaconfirmed: {
					render: (data) => {
						if (data == 1) {
							return this.$t('imofalform3items.yes');
						} else {
							return this.$t('imofalform3items.no');
						}
					}
				},
				anchoragestatus: {
					render: (data) => {
						if (data === 'Yes') {
							return this.$t('imofalform3items.yes');
						} else if (data === 'No') {
							return this.$t('imofalform3items.no');
						} else {
							return '';
						}
					}
				},
				emergencystatus: {
					render: (data, type, row) => {
						if (row.emergencystatus && row.emergencystatus == 'O') {
							setTimeout(() => {
								var rowgrid = document.getElementById(row.portcallid);

								if (rowgrid) {
									rowgrid.classList.add('emergencystatus-O');

									rowgrid.style.setProperty('background-color', 'rgba(255, 20, 0, 0.4)', 'important');
								}
							}, 0);
						}
						if (row.emergencystatus == 'O') {
							return this.$t('grid.portcalls.open');
						} else if (row.emergencystatus == 'C') {
							return this.$t('grid.portcalls.closed');
						} else {
							return null;
						}
					}
				},
				satstatus: {
					render: (data, type, row) => {
						if (row.satstatus && row.satstatus == 'P') {
							setTimeout(() => {
								var rowgrid = document.getElementById(row.portcallid);

								if (rowgrid) {
									rowgrid.classList.add('satstatus-P');

									rowgrid.style.setProperty('background-color', 'rgba(255, 252, 127, 0.4)', 'important');
								}
							}, 0);
						}
						if (row.satstatus == 'P') {
							return this.$t('grid.mships.statuslist.status.P');
						} else if (row.satstatus == 'A') {
							return this.$t('grid.portcalls.accepted');
						} else if (row.satstatus == 'R') {
							return this.$t('grid.portcalls.rejected');
						} else {
							return null;
						}
					}
				}
			}
		};
	},
	methods: {
		returnStatusList(data) {
			if (data !== null) {
				const items = data.split('|'); // Split the string by '|'
				let checkString = '';
				var counter = 0;
				items.forEach((item) => {
					const letter = item;
					let colorClass = '';
					var firstLetter = null;
					var secondLetter = null;
					// Determine the color class based on the letter
					switch (letter) {
						case 'P':
							colorClass = 'pendingStyle';
							firstLetter = 'P';
							break;
						case 'D':
							colorClass = 'draftStyle';
							firstLetter = 'D';
							break;
						case 'S':
							colorClass = 'submittedStyle';
							firstLetter = 'S';
							break;
						case 'PP':
							colorClass = 'pendingStyle';
							firstLetter = 'P';
							secondLetter = 'P';
							break;
						case 'DD':
							colorClass = 'draftStyle';
							firstLetter = 'D';
							secondLetter = 'D';
							break;
						case 'SS':
							colorClass = 'submittedStyle';
							firstLetter = 'S';
							secondLetter = 'S';
							break;
						case 'PD':
							colorClass = 'pendingDraftStyle';
							firstLetter = 'P';
							secondLetter = 'D';
							break;
						case 'PS':
							colorClass = 'pendingSubmittedStyle';
							firstLetter = 'P';
							secondLetter = 'S';
							break;
						case 'DP':
							colorClass = 'draftPendingStyle';
							firstLetter = 'D';
							secondLetter = 'P';
							break;
						case 'DS':
							colorClass = 'draftSubmittedStyle';
							firstLetter = 'D';
							secondLetter = 'S';
							break;
						case 'SD':
							colorClass = 'submittedDraftStyle';
							firstLetter = 'S';
							secondLetter = 'D';
							break;
						case 'SP':
							colorClass = 'submittedPendingStyle';
							firstLetter = 'S';
							secondLetter = 'P';
							break;
						default:
							colorClass = 'pendingStyle';
					}

					var statusArrivalLabel = '';
					if (firstLetter != null) {
						statusArrivalLabel = this.$t('grid.portcalls.statuslist.status.' + firstLetter);
					}

					var statusDepartureLabel = '';
					if (secondLetter != null) {
						statusDepartureLabel = this.$t('grid.portcalls.statuslist.status.' + secondLetter);
					}
					//const statusDepartureLabel = this.$t('grid.portcalls.statuslist.status.' + secondLetter);
					var literalHeader = this.$t('grid.portcalls.statuslist.' + counter) + '\n';
					var literalArrival = 'Arrival\nStatus: ' + statusArrivalLabel + '\n';
					var literalDeparture = 'Departure\nStatus: ' + statusDepartureLabel;
					var literalNoFalForm = 'Status: ' + statusArrivalLabel;
					var title = null;

					if (secondLetter != null) {
						title = literalHeader + literalArrival + literalDeparture;
					} else {
						title = literalHeader + literalNoFalForm;
					}
					var letterTrans = this.$t('grid.portcalls.statuslist.letters.' + counter);
					if (letterTrans != 'DL') {
						const box = `<label  title="${title}" class="statusListChecks ${colorClass}">${letterTrans}</label>`;
						checkString += box;
					}
					counter++;
				});
				return checkString;
			} else {
				return data;
			}
		},
		filter() {
			let userCodes = this.$store.getters.getSession.profiles;
			let org = this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_ORG_ID_CODE;
			let filter = null;
			let groups = [];

			if (userCodes.includes('PODI')) {
				groups.push({
					groupOp: 'and',
					groups: [],
					rules: [{ field: 'portdispatcherid', op: 'cn', data: org }]
				});
			}

			return filter;
		},
		highlightRows() {
			this.$nextTick(() => {});
		}
	},
	mounted() {},
	updated() {}
};
</script>
<style scoped>
.emergencystatus-O {
	background-color: red !important;
}

.satstatus-P {
	background-color: yellow !important;
}
</style>
